'use strict';

//*========= PC版ヘッダー　カスタムメニューのid、classを削除 =========*//
document.addEventListener('DOMContentLoaded', function() {
  const navElm = document.getElementsByClassName('l-header__menu-item');

  for (var i = 0; i < navElm.length; i++) {
    var e = navElm[i];
    if (e) {
      //idを削除
      e.removeAttribute("id");
      
      //classを削除
      const classesToRemove = Array.from(e.classList).filter(className =>
        className.startsWith("menu-item")
      );
      e.classList.remove(...classesToRemove);
    }
  }
});

//*========= PC版ヘッダー　スクロールした時の動き =========*//
document.addEventListener('DOMContentLoaded', function() {
    
  const headerElm = document.querySelector('.js-header');
  const imgElm = document.querySelector('.l-header__logo img');

  window.addEventListener('scroll', () => {

    const scrollPosition = window.pageYOffset;

    if (scrollPosition > 0) {
      headerElm.dataset.bg = "white"
      imgElm.src = tmpPath + "/assets/images/common/logo-c.svg";
    } else {
      headerElm.dataset.bg = "transparent"
      imgElm.src = tmpPath + "/assets/images/common/logo.svg";
    }
  });
});


//*========= ハンバーガーメニュー =========*//
window.addEventListener('DOMContentLoaded', function () {
const humbBtn = document.querySelector('.js-humbButton');
const humbPanel = document.querySelector('.js-humbPanel');
const humbLink = document.querySelectorAll('.js-humbPanel a');

window.addEventListener('resize', function () {
  if (!window.matchMedia('(max-width: 1200px)').matches) {
    humbBtn.classList.remove('js-humbOpen');
    humbPanel.classList.remove('js-humbActive');
    document.body.classList.remove('js-humbHidden');
  }
});


humbBtn.addEventListener('click', function () {
  this.classList.toggle('js-humbOpen');
  humbPanel.classList.toggle('js-humbActive');
  document.body.classList.toggle('js-humbHidden');
});

for (let i = 0; i < humbLink.length; i++) {
  humbLink[i].addEventListener('click', function () {
    humbBtn.classList.remove('js-humbOpen');
    humbPanel.classList.remove('js-humbActive');
    document.body.classList.remove('js-humbHidden');
  });
};
});
