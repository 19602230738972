function PageTopAnime() {
	$(window).scroll(function () {
		const scroll = $(window).scrollTop();
		if (scroll >= 200){
			$('#page-top').removeClass('DownMove');
			$('#page-top').addClass('UpMove');
		}else{
			if($('#page-top').hasClass('UpMove')){
				$('#page-top').removeClass('UpMove');
				$('#page-top').addClass('DownMove');
			}
		}
	});
	
	const pagetop_btn = document.querySelector("#page-top");
	pagetop_btn.addEventListener("click", scroll_top);
	
	function scroll_top() {
		const position = 0;
		const speed = 1000;
		$("html,body").animate({scrollTop:position},speed);
		return false;
	}
}




// 画面をスクロールをしたら動かしたい場合の記述
$(window).ready(function () {
	PageTopAnime();
});
