// 今開いているページのURLの末尾を取得
let lastUrlDirectory = location.pathname.replace(/\/+$/, '').split('/').pop();

// ページのURLがcontactの時のみ実行
if (lastUrlDirectory == 'contact') {
  // ページの読み込みが完了したら実行
  document.addEventListener('DOMContentLoaded', function () {
    // 読み込み時に実行する関数
    init();
    // id = form-typeの中のラジオボタンに変更があったら実行する関数を登録
    changeRadio();
    // 送信するボタンを押したら実行する関数を登録
    submitForm();
  });
}

// ページのURLがcontact-confirmの時のみ実行
if (lastUrlDirectory == 'contact-confirm') {
  // ページの読み込みが完了したら実行
  document.addEventListener('DOMContentLoaded', function () {
    // 読み込み時に実行する関数
    initConfirm();
  });
}

// 送信するボタンを押したら実行する関数
function submitForm() {
  // .p-contact__sendの要素を取得
  const sendButton = document.querySelector('.p-contact__send');
  // .p-contact__sendの要素がクリックされたら実行
  sendButton.addEventListener('click', function () {
    // id = form-typeのラジオボタンを取得
    const formTypeRadio = document.getElementById('form-type');
    // チェックされているラジオボタンのvalueを取得
    let strCheckValue = formTypeRadio.querySelector(
      '.p-contact__radio-button:checked'
    ).value;
    // 「採用エントリー」の場合、URLのパラメーターにtype=recruitを追加
    if (strCheckValue == '採用エントリー') {
      // URLのパラメーターにtype=recruitがある場合は何もしない
      if (location.search.indexOf('type=recruit') != -1) {
        return;
      }
      // URLのパラメーターを取得
      const params = new URLSearchParams(location.search);
      // URLのパラメーターにtype=recruitを追加
      params.set('type', 'recruit');
      // URLのパラメーターを更新
      history.replaceState('', '', '?' + params.toString());
    } else {
      // URLのパラメーターを取得
      const params = new URLSearchParams(location.search);
      // URLのパラメーターを削除
      params.delete('type');
      // URLのパラメーターを更新
      history.replaceState('', '', '?' + params.toString());
    }
  });
}

// 読み込み時に実行
function init() {
  // URLのパラメーターを取得
  const params = new URLSearchParams(location.search);
  // URLのパラメーターにtypeがあるかチェック
  if (params.get('type') == 'recruit') {
    // 「採用に関するお問い合わせ」のラジオボタンにチェックを入れる
    checkRadio('採用エントリー');
    // 「採用に関するお問い合わせ」で特定の要素を表示・非表示を切り替える
    changeDisplay('採用エントリー');
    // .p-contact__contentにクラスを追加
    document
      .querySelector('.p-contact__content')
      .classList.add('p-contact__content--recruit');
  } else {
    // 「お問い合わせの」ラジオボタンにチェックを入れる
    checkRadio('お問い合わせ');
    // 「お問い合わせの」で特定の要素を表示・非表示を切り替える
    changeDisplay('お問い合わせ');
  }
}

// お問い合わせページ上部のラジオボタンにチェックを入れる関数
function checkRadio(strCheckValue) {
  // id = form-typeのラジオボタンを取得
  const formTypeRadio = document.getElementById('form-type');
  // チェックを入れたいラジオボタンを取得
  const targetRadio = formTypeRadio.querySelector(
    '.p-contact__radio-button[value="' + strCheckValue + '"]'
  );
  // recruitRadioをチェック状態にする
  targetRadio.checked = true;
}

// 状態によって表示・非表示を切り替える関数
function changeDisplay(strCheckValue) {
  // フォームの要素を配列で定義
  const elements = {
    採用エントリー: [
      {
        id: 'form-question',
        type: 'textarea',
        display: 'p-contact__hidden',
        disabled: true,
      },
      { id: 'form-gender', type: 'input', display: '', disabled: false },
      {
        id: 'form-qualification',
        type: 'textarea',
        display: '',
        disabled: false,
      },
      {
        id: 'form-self-pr',
        type: 'textarea',
        display: '',
        disabled: false,
      },
    ],
    お問い合わせ: [
      {
        id: 'form-question',
        type: 'textarea',
        display: '',
        disabled: false,
      },
      { id: 'form-gender', type: 'input', display: 'p-contact__hidden', disabled: true },
      {
        id: 'form-qualification',
        type: 'textarea',
        display: 'p-contact__hidden',
        disabled: true,
      },
      { id: 'form-self-pr', type: 'textarea', display: 'p-contact__hidden', disabled: true },
    ],
  };

  // チェックを入れたラジオボタンによって表示・非表示を切り替える
  elements[strCheckValue].forEach((element) => {
    // フォームの要素を取得
    let formElement = document.getElementById(element.id);
    // formElementにp-contact__hiddenがある場合、削除する
    if (formElement.classList.contains('p-contact__hidden')) {
      formElement.classList.remove('p-contact__hidden');
    }
    // element.displayに文字列がある場合、フォームの要素の表示・非表示を切り替えを行うクラスを追加する
    if (element.display) {
      formElement.classList.add(element.display);
    }

    // フォームの要素のdisabledを切り替え
    if (element.type == 'input') {
      const inputElements = formElement.querySelectorAll('input');
      inputElements.forEach((input) => {
        input.disabled = element.disabled;
      });
    } else {
      let inputElement = formElement.querySelector(element.type);
      inputElement.disabled = element.disabled;
    }
  });
}

// id = form-typeの中のラジオボタンに変更があったら実行する関数を登録
function changeRadio() {
  // id = form-typeのラジオボタンの外枠を取得
  const formTypeRadio = document.getElementById('form-type');
  // formTypeRadioの中のラジオボタンを取得
  const radioButtons = formTypeRadio.querySelectorAll(
    '.p-contact__radio-button'
  );
  // ラジオボタンの数だけループ
  radioButtons.forEach(function (radioButton) {
    // ラジオボタンが変更されたら実行
    radioButton.addEventListener('change', function () {
      // チェックされているラジオボタンのvalueを取得
      let strCheckValue = formTypeRadio.querySelector(
        '.p-contact__radio-button:checked'
      ).value;

      // チェックを入れたラジオボタンによって表示・非表示を切り替える
      changeDisplay(strCheckValue);

      // チェックを入れたラジオボタンが「採用エントリー」の場合、URLのパラメーターにtype=recruitを追加
      // URLのパラメーターを取得
      const params = new URLSearchParams(location.search);

      if (strCheckValue == '採用エントリー') {
        // URLのパラメーターにtype=recruitを追加
        params.set('type', 'recruit');
        // URLのパラメーターを更新
        history.replaceState('', '', '?' + params.toString());
        // .c-heading__title-mainのテキストを変更
        document.querySelector('.c-heading__title-main').textContent =
          '採用エントリー';
        // c-heading__title-subのテキストを変更
        document.querySelector('.c-heading__title-sub').textContent =
          'Recruitment entry';
        // .p-contact__contentにクラスを追加
        document
          .querySelector('.p-contact__content')
          .classList.add('p-contact__content--recruit');
      } else {
        // URLのパラメーターを取得
        const params = new URLSearchParams(location.search);
        // URLのパラメーターを削除
        params.delete('type');
        // URLのパラメーターを更新
        history.replaceState('', '', '?' + params.toString());
        // .c-heading__title-mainのテキストを変更
        document.querySelector('.c-heading__title-main').textContent =
          'お問い合わせ';
        // c-heading__title-subのテキストを変更
        document.querySelector('.c-heading__title-sub').textContent = 'Contant';
        // .p-contact__contentにクラスを削除
        document
          .querySelector('.p-contact__content')
          .classList.remove('p-contact__content--recruit');
      }
    });
  });
}

// 読み込み時に実行
function initConfirm() {
  // URLのパラメーターを取得
  const params = new URLSearchParams(location.search);
  // URLのパラメーターにtypeがあるかチェック
  if (params.get('type') == 'recruit') {
    // お問い合わせ内容を非表示にする
    document.getElementById('form-question').style.display = 'none';
  } else {
    // 性別を非表示にする
    document.getElementById('form-gender').style.display = 'none';
    // 資格を非表示にする
    document.getElementById('form-qualification').style.display = 'none';
    // 自己PRを非表示にする
    document.getElementById('form-self-pr').style.display = 'none';
  }
}
