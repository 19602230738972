//*========= TOPメインビジュアル　テキストアニメーション =========*//
function mv() {
  let mv = document.getElementById('js-mv');
  // mvがない場合は処理を終了
  if (!mv) return;
  let texts = ['Connecting', 'your world', 'with our logistics.'];
  let currentTextIndex = 0;
  let currentText = texts[currentTextIndex];
  let i = 0;
  let speed = 50;

  function typeWriter() {
    if (i < currentText.length) {
      mv.innerHTML += currentText.charAt(i);
      i++;
      setTimeout(typeWriter, speed);
    } else {
      currentTextIndex++;
      if (currentTextIndex < texts.length) {
        i = 0;
        currentText = texts[currentTextIndex];
        mv.innerHTML += '<br>'; // Add line break before the next text
        setTimeout(typeWriter, speed);
      }
    }
  }

  typeWriter();
}

document.addEventListener('DOMContentLoaded', function () {
  mv();
});
