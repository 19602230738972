'use strict';

//*========= PC版フッター　カスタムメニューのid、classを削除 =========*//
document.addEventListener('DOMContentLoaded', function() {
  const navElm = document.getElementsByClassName('l-footer__nav-item');

  for (var i = 0; i < navElm.length; i++) {
    var e = navElm[i];
    if (e) {
      //idを削除
      e.removeAttribute("id");
      
      //classを削除
      const classesToRemove = Array.from(e.classList).filter(className =>
        className.startsWith("menu-item")
      );
      e.classList.remove(...classesToRemove);
    }
  }
});